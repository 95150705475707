<template>
  <div v-if="address">
    <div v-if="showAddressName" class="row">
      <recess-input
        v-model="address.name"
        :label-text="`${SHARED.Address.Name.Label}*`"
        :class="`col-5 qa-address-name-${addressIndex}`"
        :error-message="addressNameError()"
        :disabled="isAccountManager"
        @blur="$v.address.name.$touch()"
      />
    </div>
    <div class="row">
      <recess-input
        v-model="address.street"
        :label-text="`${SHARED.Address.Street.Label}*`"
        :class="`col-5 qa-address-street-${addressIndex}`"
        :error-message="addressStreetError()"
        @blur="$v.address.street.$touch()"
        :disabled="isAccountManager"
      />
      <recess-input
        v-model="address.number"
        :label-text="`${SHARED.Address.Number.Label}*`"
        :class="`col-2 qa-address-number-${addressIndex}`"
        :error-message="addressNumberError()"
        @input="$v.address.number.$touch()"
        :disabled="isAccountManager"
      />
      <recess-input
        v-model="address.numberAdditive"
        :label-text="`${SHARED.Address.NumberAdditive.Label}`"
        :class="`col-3 qa-address-numberAdditive-${addressIndex}`"
        :disabled="isAccountManager"
      />
      <recess-input
        v-model="address.postalCode"
        :label-text="`${SHARED.Address.PostalCode.Label}*`"
        :class="`col-2 qa-address-postalCode-${addressIndex}`"
        :error-message="addressPostalCodeError()"
        @blur="$v.address.postalCode.$touch()"
        :disabled="isAccountManager"
      />
    </div>
    <div class="row mt-4">
      <recess-input
        v-model="address.city"
        :label-text="`${SHARED.Address.City.Label}*`"
        :class="`col-7 qa-address-city-${addressIndex}`"
        :error-message="addressCityError()"
        @blur="$v.address.city.$touch()"
        :disabled="isAccountManager"
      />
      <recess-multi-select
        :selected-options="address.countryCode"
        :options="countries"
        :single-select="true"
        :search-input="true"
        :label-text="`${SHARED.Address.Country.Label}*`"
        :placeholder="SHARED.Address.Country.PlaceHolder"
        :class="`col-5 qa-country-code-${addressIndex}`"
        :error-message="addressCountryCodeError()"
        @input="(newValue) => setSelectedCountry(newValue)"
        @blur="$v.address.countryCode.$touch()"
        :disabled="isAccountManager"
      />
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { requiredIf } from "vuelidate/lib/validators";
import { isPositiveNumber } from "@/utils/validationHelpers"; // This has vue validate
import { shallowEqual } from "../../utils/validationHelpers";
import { getItems } from "@/../../shared/api/SharedClient";

import { API_CALL_URL_PATHS } from "../../constants/constantsGeneral.json";
import { SHARED } from "../../constants/EdumsSharedConstants.json";
import { isRequiredErrorMessage, isValidIntegerErrorMessage } from "../../constants/validationMessageHelper.js";

export default {
  name: "AddressForm",
  mixins: [validationMixin],
  validations: {
    address: {
      name: {
        required: requiredIf(function checkIfRequiered() {
          return this.showAddressName && !this.allFieldsAreEmpty();
        }),
      },
      street: {
        required: requiredIf(function checkIfRequiered() {
          return !this.allFieldsAreEmpty() || this.addressRequired;
        }),
      },
      number: {
        required: requiredIf(function checkIfRequiered() {
          return !this.allFieldsAreEmpty() || this.addressRequired;
        }),
        isPositiveNumber: isPositiveNumber
      },
      postalCode: {
        required: requiredIf(function checkIfRequiered() {
          return !this.allFieldsAreEmpty() || this.addressRequired;
        }),
      },
      city: {
        required: requiredIf(function checkIfRequiered() {
          return !this.allFieldsAreEmpty() || this.addressRequired;
        }),
      },
      countryCode: {
        required: requiredIf(function checkIfRequiered() {
          return !this.allFieldsAreEmpty() || this.addressRequired;
        }),
      },
    },
  },
  props: {
    isAccountManager: {
      type: Boolean,
      default() {
        return false
      }
    },
    addressInput: {
      type: Object,
      default: () => {},
    },
    addressIndex: {
      type: Number,
      default: 0,
    },
    addressRequired: {
      type: Boolean,
      default: false,
    },
    showAddressName: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      SHARED,
      countries: [],
      addressTypeCode: "Invoice",
      address: {
        name: "",
        street: "",
        number: "",
        numberAdditive: "",
        postalCode: "",
        city: "",
        countryCode: "",
      },
    };
  },
  watch: {
    address: {
      handler() {
        if (!shallowEqual(this.address, this.addressInput)) {
          this.$bus.emit("addressItemValidation", !this.$v.$invalid);
          this.$bus.emit("addressItem", {
            addressIndex: this.addressIndex,
            address: this.address,
          });
        }
      },
      deep: true,
    },
    addressInput: {
      handler() {
        this.setAddress();
      },
      deep: false,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getCountriesList();
      this.setAddress();
    });
  },
  methods: {
    setAddress() {
      this.address =
        this.addressInput === null
          ? this.address
          : JSON.parse(JSON.stringify(this.addressInput));
    },
    getCountriesList() {
      this.getCountriesListResource();
    },
    async getCountriesListResource() {
      try {
        const response = await getItems(
          process.env.VUE_APP_PROVIDER_API_URL,
          API_CALL_URL_PATHS.countries,
          1,
          99999,
          "name",
          null,
          false
        );
        if (!response) return;

        this.countries = response.items.map((item) => {
          this.countries = {
            displayText: item.displayName ? item.displayName : item.name,
            value: item.codeAlpha2,
          };
          return this.countries;
        });
      } catch (error) {
        this.getNotificationError(error);
        console.error(
          "Something went wrong while retrieving countries list",
          error
        );
      }
    },
    setSelectedCountry(newValue) {
      this.address.countryCode = newValue;
      this.$v.$touch();
    },
    addressNameError() {
      return isRequiredErrorMessage("name", "Naam", this.$v.address.name);
    },
    addressStreetError() {
      return isRequiredErrorMessage("street", "Straat", this.$v.address.street);
    },
    addressNumberError() {
      if (!this.$v.address.number.$dirty) return null;

      return (
        isValidIntegerErrorMessage(SHARED.Address.Number.Label, this.$v.address.number, 'isPositiveNumber') ||
        isRequiredErrorMessage("number", SHARED.Address.Number.Label, this.$v.address.number)
      )
    },
    addressPostalCodeError() {
      return isRequiredErrorMessage(
        "postalCode",
        "Postcode",
        this.$v.address.postalCode
      );
    },
    addressCityError() {
      return isRequiredErrorMessage("city", "Stad", this.$v.address.city);
    },
    addressCountryCodeError() {
      return isRequiredErrorMessage("countryCode", "Land", this.$v.address.countryCode);
    },
    allFieldsAreEmpty() {
      return (
        !this.address.street &&
        !this.address.number &&
        !this.address.numberAdditive &&
        !this.address.postalCode &&
        !this.address.city &&
        !this.address.countryCode
      );
    },
  },
};
</script>