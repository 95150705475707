import { helpers } from 'vuelidate/lib/validators'

export function shallowEqual(object1, object2) {    
    const keys1 = Object.keys(object1)
    const keys2 = Object.keys(object2)

    if (keys1.length !== keys2.length) {
        return false
    }
    return !keys1.some((key) => {
        return `${object1[key]}` !== `${object2[key]}`
    })
}

export const isValidUrl = helpers.regex('isValidUrl', /^https?:\/\/(?:www\.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[a-zA-Z]{2,}([:][0-9]{1,5})?(\b(?:[-a-zA-Z0-9%&#_\/.]*))/)

export default { 
    shallowEqual, 
    isValidUrl 
}